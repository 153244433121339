import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { apiSecure } from "../../components/api";
import { Loading } from "../../components/Loading/Loading";
import "./ExcelPage.css";

import { MenuFoldOutlined, MenuOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "../../components/Translate";
import { useDispatch, useSelector } from "react-redux";
import config, { getEnvSettings } from "../../config/config";
import { NonEditableCell, NumberCell, ReactGrid, TextCell } from "@silevis/reactgrid";
import { Button } from "antd";




// Tämä voi olla esimerkiksi utils/hooks.js tai suoraan tiedostosi yläreunassa
const useRowDebounce = () => {
  const rowTimers = useRef({}); // Tallentaa ajastimet rivikohtaisesti

  const debounce = (rowId, callback, delay) => {
    if (rowTimers.current[rowId]) {
      clearTimeout(rowTimers.current[rowId]);
    }

    rowTimers.current[rowId] = setTimeout(() => {
      callback();
      delete rowTimers.current[rowId]; // Poista ajastin, kun tallennus on valmis
    }, delay);
  };

  return debounce;
};


const createValuesArr = (
  kpl = 0,
  pituus = 0,
  korkeus = 0,
  paksuus = 0,
  m2 = 0,
  m3 = 0,
  t = 0,
  raudoite = 0,
) => {
  return {
    kpl: { showSummary: true, canEdit: true, id: 'kpl', value: kpl },
    pituus: { showSummary: true, canEdit: true, id: 'pituus', value: pituus },
    korkeus: { showSummary: false, canEdit: true, id: 'korkeus', value: korkeus },
    paksuus: { showSummary: false, canEdit: true, id: 'paksuus', value: paksuus },
    m2: { showSummary: true, canEdit: false, id: 'm2', value: m2 },
    m3: { showSummary: true, canEdit: false, id: 'm3', value: m3 },
    t: { showSummary: true, canEdit: true, id: 't', value: t },
    raudoite: { showSummary: true, canEdit: true, id: 'raudoite', value: raudoite },
  }
}


const createSummaryRow = (data) => {
  const initialValues = createValuesArr(); // Luo tyhjä malli yhdistämistä varten

  const summaryValues = data.reduce((acc, curr) => {
    // Käydään läpi rivin arvot (objektina, joten käytetään Object.values)
    Object.values(curr.values).forEach((item) => {
      if (!item.showSummary) {
        acc[item.id].value = ""; // Ei lasketa näitä
        return;
      }

      // Jos kenttä on numeerinen, summaamme sen
      if (typeof item.value === 'number') {
        if (item.id === 'pituus') {
          // Lasketaan pituus (kpl * pituus / 1000)
          const kplValue = curr.values.kpl?.value || 0;
          acc[item.id].value += (item.value / 1000) * kplValue;
        } else {
          acc[item.id].value += item.value; // Muut numeeriset arvot
        }
      }
    });
    return acc;
  }, { ...initialValues }); // Kopioi rakenne yhdistämistä varten

  // Lisää yksiköt tarvittaviin kenttiin
  return {
    label: "Yhteensä", // Yhteenvetorivin nimi
    values: Object.fromEntries(
      Object.entries(summaryValues).map(([key, item]) => {
        if (item.id === 'pituus') {
          return [key, { ...item, value: `${item.value} jm` }]; // Lisää "jm" laskettuun pituuteen
        }
        if (item.id === 'kpl') {
          return [key, { ...item, value: `${item.value} kpl` }]; // Lisää "kpl" kappalemäärään
        }
        if (item.id === 'm2') {
          return [key, { ...item, value: `${item.value} m²` }];
        }
        if (item.id === 'm3') {
          return [key, { ...item, value: `${item.value} m³` }];
        }
        if (item.id === 't') {
          return [key, { ...item, value: `${item.value} t` }];
        }
        if (item.id === 'raudoite') {
          return [key, { ...item, value: `${item.value} kg` }];
        }
        return [key, item]; // Muut arvot pysyvät ennallaan
      })
    ),
  };
};

// Lasketaan m2 ja m3 yhdellä funktiolla
const calculateRowValues = (row) => {
  console.log('row', row)
  const pituus = row.values["pituus"]?.value || 0;
  const korkeus = row.values["korkeus"]?.value || 0;
  const paksuus = row.values["paksuus"]?.value || 0;

  row.values["m2"].value = ((pituus / 1000) * (korkeus / 1000));
  row.values["m3"].value = ((pituus / 1000) * (korkeus / 1000) * (paksuus / 1000));

  return row.values
};



// ReactGridExample-komponentti
const ReactGridExample = ({ elements, getElements, saveData }) => {
  const [headerCols, setHeaderCols] = useState([
    { name: "Tunnus" },
    { name: "Kpl" },
    { name: "Pituus" },
    { name: "Korkeus" },
    { name: "Paksuus" },
    { name: "m²" },
    { name: "m³" },
    { name: "t" },
    { name: "Raudoite" },
  ]);

  const [elementsData, setElementsData] = useState([]);
  const debouncedSaveRow = useRowDebounce(); // Ota käyttöön debounce-logiikka



// Päivitetään rivin tiedot kerralla
const updateRowValues = (rows, rowIndex, valueType, newValue) => {
  const updatedRows = [...rows];
  const rowToUpdate = { ...updatedRows[rowIndex] };


  // Jos arvo ei oikasti muutu, skipataan loput
  if (rowToUpdate.values[valueType].value === newValue) {
    return updatedRows;
  }


  // Päivitetään vain muuttunut kenttä
  rowToUpdate.values[valueType].value = newValue

  rowToUpdate.isUpdated = true

  // Lasketaan m2 ja m3 arvot
  rowToUpdate.values = calculateRowValues(rowToUpdate);

  updatedRows[rowIndex] = rowToUpdate;

  // Kutsu debounce-logiikkaa rivin tallentamiseksi
  debouncedSaveRow(rowToUpdate.id, () => saveData(rowToUpdate), 3000);
  //debouncedSaveRow(rowToUpdate.id, () => console.log('rowToUpdate', rowToUpdate), 3000);

  return updatedRows;
};



  // Elements Initial
  useEffect(() => {
    if (elements.length <= 0) return;
  
    const formattedElements = elements.map((elemData) => ({
      id: elemData.ElementtiNro,
      label: elemData.Tunnus,
      values: createValuesArr(
        elemData.KPL,
        elemData.Pituus,
        elemData.Korkeus,
        elemData.Paksuus,
        elemData.m2,
        elemData.m3,
        elemData.Paino,
      ),
    }));
  
    setElementsData(formattedElements);
  }, [elements]);


  console.log('elementsData', elementsData)
  const summaryRow = createSummaryRow(elementsData);
  console.log('summaryRow', summaryRow)

  const cells = [
    // Header cells
    ...headerCols.map((row, colIndex) => ({
      rowIndex: 0,
      colIndex: colIndex,
      Template: NonEditableCell,
      props: { value: row.name, style: cellStyles.header },
    })),

    // Year data cells
    ...elementsData
      .map((elemData, rowIndex) => [
        // Elementin tunnus
        {
          rowIndex: rowIndex + 1,
          colIndex: 0,
          Template: TextCell,
          props: {
            text: elemData.label,
            onTextChanged: (newValue) => {
              setElementsData((prev) => {
                const updatedRows = [...prev];
                updatedRows[rowIndex].label = newValue;
                return updatedRows;
              });
            },
          },
        },

        // Elementin arvot
        ...Object.keys(elemData.values).map((valueType, colIndex) => {
          const valueRow = elemData.values[valueType]
          const isEditable = valueRow.canEdit;

          return {
            rowIndex: rowIndex + 1,
            colIndex: colIndex + 1,
            Template: !isEditable ? NonEditableCell : NumberCell,
            props: {
              value: valueRow.value,
              ...(isEditable && {
                onValueChanged: (newValue) => {

                  console.log('newValue', newValue)
                  if (isNaN(newValue)) return

                  setElementsData((prev) =>
                    updateRowValues(prev, rowIndex, valueType, newValue)
                  );
                },
              }),
              style: !isEditable ? cellStyles.disableEdit : {},
            },
          };
        }),
      ])
      .flat(),

    // Summary row
    ...Object.keys(summaryRow.values).map((valueKey, colIndex) => {
      const value = summaryRow.values[valueKey]
      return {
        rowIndex: elementsData.length + 1,
        colIndex: colIndex + 1,
        Template: NonEditableCell,
        props: { value: value.value, style: cellStyles.footer },
      }
    }),
  ];

  console.log('cells', cells)

  return <>

    <ReactGrid
      id="exceltest"
      cells={cells}
      columns={[
        {
          colIndex: 0,
          width: "100px"
        },
        {
          colIndex: 1,
          minWidth: "10px",
        }
      ]}
      styles={rgStyles}
    />

  </>

};


//Add new row
/*     {
      rowIndex: elementsData.length + 1, colIndex: 0, Template: TextCell, props: {
        text: '',
        onTextChanged: (newValue) => {
          console.log('newValue', newValue)


          setElementsData((prev) => {
            const updatedYears = [...prev,
            {
              id: 13, label: newValue, values: createValuesArr()
            }
            ];
            return updatedYears;
          });
        },

      }
    }, */


const cellStyles = {
  header: {
    backgroundColor: "#F9F9F9",
    display: "flex",
    alignItems: "center",
    fontFamily: "DM Sans Bold",
  },
  disableEdit: {
    backgroundColor: "#F9F9F9",
  },
  footer: {
    backgroundColor: "#F9F9F9",
    display: "flex",
    alignItems: "center",
    fontFamily: "DM Sans Bold",
  }
};


const rgStyles = {
  focusIndicator: {
    border: {
      color: "#0C2FFA",
      width: "2px",
      style: "solid",
    },
  },
  selectionIndicator: {
    background: "rgba(12,47,250,0.1)",
    border: {
      color: "#0C2FFA",
      style: "solid",
    },
  },
  fillHandle: {
    background: "transparent",
    border: {
      color: "#0C2FFA",
      style: "dashed",
    },
  },
  gridWrapper: {
    fontSize: 16,
    fontFamily: "DM Sans Regular",
    borderRadius: 20
  },
};



export const ExcelPage = ({ setToken }) => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(true);

  const [elements, setElements] = useState([]);
  console.log('elements', elements)

  const getElements = () => {
    setisLoading(true);
    console.log("getMapProjects");

    let req_config = {
      method: "POST",
      url: `intra_getExcelData`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);
        setElements(response?.data?.data?.elements);

        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getElements();
  }, []);


  const saveData = (elemData) => {
   // setisLoading(true);
    console.log("saveData", elemData);

    let req_config = {
      method: "POST",
      url: `intra_saveExcelData`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        elemData: elemData
      }
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);
        //setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        //setisLoading(false);
      });
  };



  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="map-page-main">
      <div style={{ display: 'flex', flexDirection: 'column' }}>

        <ReactGridExample elements={elements} getElements={getElements} saveData={saveData} />

      </div>

    </div>
  );
};
