import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";
import { useDispatch, useSelector } from "react-redux";



import AllActions from "../../store/actions";

import logo from "../../assets/images/ybtlogo.png"; // Tell webpack this JS file uses this image
import Icon, { MenuFoldOutlined, MenuOutlined, UserOutlined, SearchOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { useTranslate } from "../Translate";

import { Avatar, ConfigProvider, Dropdown, Menu } from "antd";
import { FactoryMenu, FactorySwitcher } from "../FactorySwitcher/FactorySwitcher";

import { LanguageMenu, LanguageSwitcher } from "../LanguageSwitcher/LanguageSwitcher";

import config, { getEnvSettings } from "../../config/config";
import { Popover } from "antd/lib";
import axios from "axios";
import { apiSecure } from "../api";

const LangSvg = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" >
    <path d="M7.6918 3.06348C7.6918 3.06348 7.02734 5.63379 5.44141 7.57519C3.85547 9.5166 2.1875 10.501 2.1875 10.501M1.3125 3.06348H9.1875H1.3125ZM5.25 1.75098V3.06348V1.75098ZM7.4375 12.251L10.0625 6.12598L12.6875 12.251H7.4375ZM8.24414 10.501H11.8809H8.24414Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 9.18896C7 9.18896 6.04297 8.45068 5.03125 7.13818C4.01953 5.82568 3.5 4.81396 3.5 4.81396" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const LogoutSvg = () => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none">
    <g clipPath="url(#clip0_1434_724)">
      <path d="M9.81825 5.04475L2.7425 5.04154C2.62094 5.04154 2.50437 5.08983 2.41841 5.17578C2.33246 5.26174 2.28417 5.37831 2.28417 5.49987C2.28417 5.62143 2.33246 5.73801 2.41841 5.82396C2.50437 5.90992 2.62094 5.95821 2.7425 5.95821L9.81046 5.96141L8.47534 7.29608C8.38941 7.38203 8.34114 7.49859 8.34114 7.62012C8.34114 7.74166 8.38941 7.85821 8.47534 7.94416C8.56129 8.03009 8.67784 8.07836 8.79938 8.07836C8.92091 8.07836 9.03747 8.03009 9.12342 7.94416L10.597 6.47246C10.7247 6.34476 10.8261 6.19315 10.8952 6.02627C10.9644 5.85939 11 5.68051 11 5.49987C11 5.31923 10.9644 5.14036 10.8952 4.97348C10.8261 4.8066 10.7247 4.65498 10.597 4.52729L9.12479 3.05558C9.03884 2.96966 8.92229 2.92139 8.80075 2.92139C8.67922 2.92139 8.56266 2.96966 8.47671 3.05558C8.39079 3.14153 8.34252 3.25809 8.34252 3.37962C8.34252 3.50116 8.39079 3.61771 8.47671 3.70366L9.81825 5.04475Z" fill="black" />
      <path d="M3.20866 10.0833L1.37533 10.0833C1.25377 10.0833 1.13719 10.035 1.05124 9.94909C0.965286 9.86314 0.916997 9.74656 0.916997 9.625L0.916998 1.375C0.916998 1.25344 0.965286 1.13686 1.05124 1.05091C1.13719 0.964955 1.25377 0.916667 1.37533 0.916667L3.20866 0.916667C3.33022 0.916667 3.4468 0.868378 3.53275 0.782424C3.6187 0.69647 3.66699 0.579891 3.66699 0.458333C3.66699 0.336776 3.6187 0.220197 3.53275 0.134243C3.4468 0.0482886 3.33022 -1.47209e-08 3.20866 -2.00343e-08L1.37533 -1.00172e-07C1.01066 -1.16112e-07 0.660923 0.144866 0.40306 0.402728C0.145199 0.660591 0.000333265 1.01033 0.000333249 1.375L0.000332888 9.625C0.000332873 9.98967 0.145198 10.3394 0.40306 10.5973C0.660922 10.8551 1.01066 11 1.37533 11L3.20866 11C3.33022 11 3.4468 10.9517 3.53275 10.8658C3.6187 10.7798 3.66699 10.6632 3.66699 10.5417C3.66699 10.4201 3.6187 10.3035 3.53275 10.2176C3.4468 10.1316 3.33022 10.0833 3.20866 10.0833Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_1434_724">
        <rect width="11" height="11" fill="white" transform="translate(11) rotate(90)" />
      </clipPath>
    </defs>
  </svg>
);

const LangIcon = (props) => <Icon component={LangSvg} {...props} />;
const LogoutIcon = (props) => <Icon component={LogoutSvg} {...props} />;


const HeaderPFP = ({ logOut }) => {
  const { locale } = useTranslate();
  const { name, userid } = useSelector((state) => state.currentUser.user);
  const envConfig = getEnvSettings();

  // Alustetaan tila kuvalle
  const [profileImage, setProfileImage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Kutsutaan API:ta, joka hakee kuvan URL:n käyttäjän ID:n perusteella
    const fetchProfileImage = async () => {
      try {
        if (!userid) return

        // Profiilikuvat vain intrassa
        if (envConfig.env !== "intra") return

        const url = `${config.api_url}${envConfig.apiUrlPrefix}profileimage/${userid}`;

        const response = await apiSecure(url, {
          responseType: 'blob' // Tämä tarvitaan, jos haluat käsitellä kuvaa binäärinä
        });


        if (response.status == 200) {
          // Kun kuva haettu onnistuneesti, luodaan URL
          const imageUrl = URL.createObjectURL(response.data);
          setProfileImage(imageUrl); // Asetetaan kuva URL-tilaan
        }

      } catch (error) {
        console.log('Kuvan lataus epäonnistui:', error);
      } finally {
        setLoading(false); // Lopetetaan latauksen tila
      }
    };

    fetchProfileImage();
  }, [userid]); // Suoritetaan aina, kun `envConfig.apiUrlPrefix` muuttuu




  return (
    <div className="header-avatar">
      <Popover
        placement="bottomRight"
        title={
          <div className="header-avatar-title">
            {loading ? (
              // Näytetään latauksen aikana jokin placeholder, esim. spinner tai tyhjä ikoni
              <Avatar icon={<UserOutlined />} />
            ) : (
              <Avatar
                src={profileImage || undefined} // Näytetään kuva tai ikoni, jos kuvaa ei ole
                icon={!profileImage && <UserOutlined />} // Jos kuvaa ei ole, näytetään oletusikoni
              />
            )}
            <p className="header-avatar-title-text font-dm-regular">{name}</p>
          </div>
        }
        content={
          <div className="header-avatar-items">
            <div className="header-avatar-item">
              <LangIcon className="header-avatar-item-icon" />
              <div className="header-avatar-item-text">
                <LanguageSwitcher />
              </div>
            </div>

            <div className="header-avatar-item">
              <LogoutIcon className="header-avatar-item-icon" />
              <p className="header-avatar-item-text font-dm-medium clickable" onClick={logOut}>
                {locale.logout}
              </p>
            </div>
          </div>
        }
        trigger="click"
      >
        {loading ? (
          // Näytetään latauksen aikana jokin placeholder, esim. spinner tai tyhjä ikoni
          <Avatar size={25} icon={<UserOutlined />} />
        ) : (
          <Avatar
            size={25}
            src={profileImage || undefined} // Näytetään kuva tai ikoni, jos kuvaa ei ole
            icon={!profileImage && <UserOutlined />} // Jos kuvaa ei ole, näytetään oletusikoni
          />
        )}

      </Popover>
    </div>
  );
};


export const Header = () => {
  const dispatch = useDispatch();
  const { locale } = useTranslate();
  const envConfig = getEnvSettings();

  const { showSearch } = useSelector((state) => state.currentUser);

  const location = useLocation();
  const currPath = location.pathname

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1000 });
  const [menuVisible, setMenuVisible] = useState(false);

  const [show, setShow] = useState(true);

  const logOut = () => {
    console.log("LOG_OUT");
    dispatch(AllActions.userActions.logOut());
  };

  const onClick = ({ key }) => {
    console.log(`Click on item ${key}`);

    if (key === "search") {
      dispatch(AllActions.userActions.updateShowSearch(false));
    }

    if (key === "fi") dispatch(AllActions.userActions.updateLang("fi"));
    if (key === "se") dispatch(AllActions.userActions.updateLang("se"));
    if (key === "en") dispatch(AllActions.userActions.updateLang("en"));

    if (key.includes("factory")) {
      const factoryNumber = key.replace("factory_", "");
      dispatch(AllActions.userActions.updateFactory(factoryNumber));
    }

    //updateLang(key);
    //setMenuVisible(false);
  };


  const menu = [
    {
      label: (
        <Link to={"/projects"} className="headerbtnlink">
          <p className="headercontacts font-dm-medium">{locale.projects}</p>
        </Link>
      ),
      key: "projects",
    },

    envConfig.showMap &&
    {
      label: (
        <Link to={"/map"} className="headerbtnlink">
          <p className="headercontacts font-dm-medium">{locale.map}</p>
        </Link>
      ),
      key: "map",
    },

    envConfig.env === "intra" &&
    {
      label: (
        <p className="headercontacts font-dm-medium">{locale.search}</p>
      ),
      key: "search",
    },

    ...FactoryMenu(),
    ...LanguageMenu(),
    {
      type: "divider",
    },

    {
      label: (
        <Link to={""} className="headerbtnlink" onClick={logOut}>
          <p className="headercontacts font-dm-medium">{locale.logout}</p>
        </Link>
      ),
      key: "logout",
    },
  ]


  return (
    <>
      <div className={show ? "header" : "hiddenHeader"}>
        <div className="headerInside" >
          <img
            className="headerlogo"
            src={logo}
            alt="YBT Oy"
            onClick={() => {
              window.location = "/";
            }}
            href="someLink"
          />

          {!isTabletOrMobile && envConfig.showFactorySel ?
            <div className="factorySwDiv clickable">
              <FactorySwitcher />
            </div>
            : <></>}

          {!isTabletOrMobile ? (
            <div className="header-btns">

              {envConfig.showMap ?
                <Link to={"/excel"} className={`headerbtnlink`}>
                  <p className={`headerprojects ${currPath.includes("/excel") ? 'font-dm-bold' : 'font-dm-regular'}`}>{"Excel"}</p>
                </Link>
                : <></>}

              {envConfig.showMap ?
                <Link to={"/map"} className={`headerbtnlink`}>
                  <p className={`headerprojects ${currPath.includes("/map") ? 'font-dm-bold' : 'font-dm-regular'}`}>{locale.map}</p>
                </Link>
                : <></>}

              {envConfig.showSchedule ?
                <Link to={"/schedule"} className={`headerbtnlink`}>
                  <p className={`headerprojects ${currPath.includes("/schedule") ? 'font-dm-bold' : 'font-dm-regular'}`}>{locale.schedule}</p>
                </Link>
                : <></>}

              <Link to={"/projects"} className={`headerbtnlink`}>
                <p className={`headerprojects ${currPath.includes("/project") ? 'font-dm-bold' : 'font-dm-regular'}`}>{locale.projects}</p>
              </Link>


              {envConfig.env === "intra" &&
                <div className="headercontacts headerbtnlink clickable" onClick={() => {
                  dispatch(AllActions.userActions.updateShowSearch(showSearch));
                }}>
                  <SearchOutlined />
                </div>
              }

              <HeaderPFP logOut={logOut} />
            </div>
          ) : (
            <Dropdown

              menu={{ items: menu, onClick }}
              trigger={["click"]}
              open={menuVisible}
              onOpenChange={(visible) => {
                setMenuVisible(visible);
              }}
              placement="bottomRight"
            >
              {menuVisible ? (
                <MenuFoldOutlined className="header-dropdown" />
              ) : (
                <MenuOutlined className="header-dropdown" />
              )}
            </Dropdown>
          )}
        </div>

      </div>
    </>
  );
};